import React from "react";
import { Container, Row, Col, Badge, Table } from "react-bootstrap";
import Datacard from "../datacard/datacard";

import "./bleachingdashboard.css";

const Bleachingdashboard = (props) => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <Container fluid>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  PRE BLEACH WP
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "431LI-003")} />
                <Datacard title={dcsTagFinder(props.data, "431QC-078")} />
                <Datacard title={dcsTagFinder(props.data, "431FC-006")} />
                <Datacard title={dcsTagFinder(props.data, "431FC-007")} />
                <Datacard title={dcsTagFinder(props.data, "431II-E001-1")} />
                <Datacard title={dcsTagFinder(props.data, "431PC-016")} />
                <Datacard title={dcsTagFinder(props.data, "431II-C001-1")} />
                <Datacard title={dcsTagFinder(props.data, "431FC-031")} />
                <Datacard title={dcsTagFinder(props.data, "431LC-010")} />
              </Col>
            </Row>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  DO WASH PRESS
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "431LC-103.1")} />
                <Datacard title={dcsTagFinder(props.data, "431II-P001-1")} />
                <Datacard title={dcsTagFinder(props.data, "431II-E012-1")} />
                <Datacard title={dcsTagFinder(props.data, "431LC-135")} />
                <Datacard title={dcsTagFinder(props.data, "431QI-115")} />
                <Datacard title={dcsTagFinder(props.data, "431QC-114")} />
                <Datacard title={dcsTagFinder(props.data, "431QI-117")} />
                <Datacard title={dcsTagFinder(props.data, "431FC-423.1")} />
                <Datacard title={dcsTagFinder(props.data, "431LC-118")} />
                <Datacard title={dcsTagFinder(props.data, "431QC-120")} />
                <Datacard title={dcsTagFinder(props.data, "431FC-201")} />
                <Datacard title={dcsTagFinder(props.data, "431FC-204")} />
                <Datacard title={dcsTagFinder(props.data, "431II-E002-1")} />
                <Datacard title={dcsTagFinder(props.data, "431PC-214")} />
                <Datacard title={dcsTagFinder(props.data, "431C002-1")} />
                <Datacard title={dcsTagFinder(props.data, "431FC-227")} />
                <Datacard title={dcsTagFinder(props.data, "431LC-206")} />
                <Datacard title={dcsTagFinder(props.data, "431PDI-139")} />
              </Col>
            </Row>
          </Container>
        </Col>
        <Col>
          <Container fluid>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  EOP WASH PRESS
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "431FC-226")} />
                <Datacard title={dcsTagFinder(props.data, "431FI-307.2")} />
                <Datacard title={dcsTagFinder(props.data, "431LI-309")} />
                <Datacard title={dcsTagFinder(props.data, "431QI-310")} />
                <Datacard title={dcsTagFinder(props.data, "431PC-311")} />
                <Datacard title={dcsTagFinder(props.data, "431LC-303.1")} />
                <Datacard title={dcsTagFinder(props.data, "431II-P003-1")} />
                <Datacard title={dcsTagFinder(props.data, "431TC-312")} />
                <Datacard title={dcsTagFinder(props.data, "431QC-313")} />
                <Datacard title={dcsTagFinder(props.data, "431QI-316")} />
                <Datacard title={dcsTagFinder(props.data, "431LC-320")} />
                <Datacard title={dcsTagFinder(props.data, "431QC-322")} />
                <Datacard title={dcsTagFinder(props.data, "431FC-321")} />
                <Datacard title={dcsTagFinder(props.data, "431II-E003-1")} />
                <Datacard title={dcsTagFinder(props.data, "431PC-333")} />
                <Datacard title={dcsTagFinder(props.data, "431II-C003-1")} />
                <Datacard title={dcsTagFinder(props.data, "431FC-339")} />
                <Datacard title={dcsTagFinder(props.data, "431LC-324")} />
                <Datacard title={dcsTagFinder(props.data, "431PDI-131")} />
              </Col>
            </Row>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  D1 WASH PRESS
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "431LC-403.1")} />
                <Datacard title={dcsTagFinder(props.data, "431II-P005-1")} />
                <Datacard title={dcsTagFinder(props.data, "431FI-407-5")} />
                <Datacard title={dcsTagFinder(props.data, "431FC-423.2")} />
                <Datacard title={dcsTagFinder(props.data, "431QC-410")} />
                <Datacard title={dcsTagFinder(props.data, "431TC-409")} />
                <Datacard title={dcsTagFinder(props.data, "431LC-415")} />
                <Datacard title={dcsTagFinder(props.data, "431QI-414")} />
                <Datacard title={dcsTagFinder(props.data, "431QC-419")} />
                <Datacard title={dcsTagFinder(props.data, "431FC-418")} />
                <Datacard title={dcsTagFinder(props.data, "431II-E004-1")} />
                <Datacard title={dcsTagFinder(props.data, "431PC-429")} />
                <Datacard title={dcsTagFinder(props.data, "431II-C004-1")} />
                <Datacard title={dcsTagFinder(props.data, "431FC-440")} />
                <Datacard title={dcsTagFinder(props.data, "431LC-434")} />
              </Col>
            </Row>
          </Container>
        </Col>
        <Col>
          <Container fluid>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  CLEANER
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "431LC-503")} />
                <Datacard title={dcsTagFinder(props.data, "431QC-502")} />
                <Datacard title={dcsTagFinder(props.data, "431QC-042")} />
              </Col>
            </Row>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  DECKER
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "431LC-534")} />
                <Datacard title={dcsTagFinder(props.data, "431II-E005-1")} />
                <Datacard title={dcsTagFinder(props.data, "431II-E006-1")} />
                <Datacard title={dcsTagFinder(props.data, "431LC-538")} />
              </Col>
            </Row>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">HD</div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "431LC-603.1")} />
                <Datacard title={dcsTagFinder(props.data, "431II-P007-1")} />
                <Datacard title={dcsTagFinder(props.data, "431LI-607")} />
                <Datacard title={dcsTagFinder(props.data, "431LI-610")} />
                <Datacard title={dcsTagFinder(props.data, "431QI-646")} />
                <Datacard title={dcsTagFinder(props.data, "431FI-647")} />
                <Datacard title={dcsTagFinder(props.data, "431LC-618")} />
                <Datacard title={dcsTagFinder(props.data, "431QC-040")} />
                <Datacard title={dcsTagFinder(props.data, "431QI-115-7")} />
                <Datacard title={dcsTagFinder(props.data, "431FQ-621")} />
                <Datacard title={dcsTagFinder(props.data, "431FI-132")} />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Bleachingdashboard;

function dcsTagFinder(data, tagname) {
  const filterArray = data.filter((item) => item.dcstag === tagname);
  return filterArray[0] || { dcstag: "x", tagdesc: "x", value: "x" };
}

import React from "react";
import { Container, Row, Col, Badge, Table } from "react-bootstrap";
import Datacard from "../datacard/datacard";

import "./chemicaldashboard.css";

const Chemicaldashboard = (props) => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <Container fluid>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  STORAGE TANK
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "611LI-131")} />
                <Datacard title={dcsTagFinder(props.data, "611LI-132")} />
                <Datacard title={dcsTagFinder(props.data, "611LI-160")} />
                <Datacard title={dcsTagFinder(props.data, "611LI-170")} />
                <Datacard title={dcsTagFinder(props.data, "611LI-180")} />
                <Datacard title={dcsTagFinder(props.data, "611LC-190")} />
              </Col>
            </Row>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  CLO2 SVP 2
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "622FC-267")} />
                <Datacard title={dcsTagFinder(props.data, "622FC-263")} />
                <Datacard title={dcsTagFinder(props.data, "622FC-266")} />
                <Datacard title={dcsTagFinder(props.data, "622LI-220")} />
                <Datacard title={dcsTagFinder(props.data, "622PI-224")} />
                <Datacard title={dcsTagFinder(props.data, "602TC-112")} />
                <Datacard title={dcsTagFinder(props.data, "622QI-283")} />
                <Datacard title={dcsTagFinder(props.data, "622PI-273")} />
                <Datacard title={dcsTagFinder(props.data, "622FI-285")} />
              </Col>
            </Row>
          </Container>
        </Col>
        <Col>
          <Container fluid>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  CLO2 PLANT3
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "623FIC052")} />
                <Datacard title={dcsTagFinder(props.data, "623FIC050")} />
                <Datacard title={dcsTagFinder(props.data, "623FIC031")} />
                <Datacard title={dcsTagFinder(props.data, "623PIC062")} />
                <Datacard title={dcsTagFinder(props.data, "623TI124")} />
                <Datacard title={dcsTagFinder(props.data, "623AIC127")} />
                <Datacard title={dcsTagFinder(props.data, "623FI133")} />
              </Col>
            </Row>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  CHILLER LINE2
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "602PI-115")} />
                <Datacard title={dcsTagFinder(props.data, "602PI-116")} />
                <Datacard title={dcsTagFinder(props.data, "602TC-112")} />
              </Col>
            </Row>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  CHILLER LINE3
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "623FI395")} />
                <Datacard title={dcsTagFinder(props.data, "623FI391")} />
                <Datacard title={dcsTagFinder(props.data, "623TI124")} />
              </Col>
            </Row>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  CLO2 STORAGE TANK
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "621LI-291")} />
                <Datacard title={dcsTagFinder(props.data, "621LI-292")} />
                <Datacard title={dcsTagFinder(props.data, "622LI-291")} />
                <Datacard title={dcsTagFinder(props.data, "622LI-292")} />
                <Datacard title={dcsTagFinder(props.data, "621FI-299")} />
                <Datacard title={dcsTagFinder(props.data, "622FI-289")} />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Chemicaldashboard;

function dcsTagFinder(data, tagname) {
  const filterArray = data.filter((item) => item.dcstag === tagname);
  return filterArray[0] || { dcstag: "x", tagdesc: "x", value: "x" };
}

import React, { useState, useEffect } from "react";
import { MemoryRouter, Routes, Route } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import Navtop from "./components/navbar";
import { PageLayout } from "./components/PageLayout";
import Dashboard from "./components/dashboard/dashboard";

import Container from "react-bootstrap/Container";

import "./App.css";

const About = () => <span>About 555</span>;

const Chart = () => <span>Chart</span>;

function App() {
  const [pageName, setPageName] = useState("#dashboard");

  return (
    <Navtop page={setPageName}>
      <AuthenticatedTemplate>
        <div className="maincontent">{pageDisplay(pageName)}</div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <h4 className="position-absolute bottom-50 end-50 text-center">You are not logged In</h4>
      </UnauthenticatedTemplate>
    </Navtop>
  );
}

function pageDisplay(pageName) {
  switch (pageName) {
    case "#dashboard":
      return <Dashboard />;
      break;
    case "#chart":
      return <Chart />;
      break;
    case "#about":
      return <About />;
      break;
    default:
      return <Dashboard />;
  }
}

export default App;

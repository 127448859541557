import React, { useState, useEffect } from "react";
import {
  Container,
  Accordion,
  Row,
  Col,
  Badge,
  Spinner,
} from "react-bootstrap";
import { dcsSampleData } from "../../samples/data";
import io from "socket.io-client";

import Cookingdashboard from "../cookingdashboard/cookingdashboard";
import Brownstockdashboard from "../brownstockdashboard/brownstockdashboard";
import Bleachingdashboard from "../bleachingdashboard/bleachingdashboard";
import Chemicaldashboard from "../chemicaldashboard/chemicaldashboard";
import "./dashboard.css";
import dayjs from "dayjs";

const socket = io("https://api-pulp1-socketio.netcomdns.com");

const Pulpdashboard = () => {
  const [dcs, setDcs] = useState(dcsSampleData);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [lastPong, setLastPong] = useState(null);

  useEffect(() => {
    socket.on("connect", () => {
      setIsConnected(true);
    });

    socket.on("disconnect", () => {
      setIsConnected(false);
    });

    socket.on("dcsdata", function (msg) {
      setLastPong(dayjs());
      setDcs(msg);
    });
  }, []);
  if (lastPong === null)
    return (
      <div className="position-absolute bottom-50 end-50 text-center">
        <Spinner animation="border" variant="secondary" />
        <p>Feching Data. Please wait...</p>
      </div>
    );
  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Cooking</Accordion.Header>
        <Accordion.Body>
          <Cookingdashboard data={dcs} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Brownstock</Accordion.Header>
        <Accordion.Body>
          <Brownstockdashboard data={dcs} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Bleaching</Accordion.Header>
        <Accordion.Body>
          <Bleachingdashboard data={dcs} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Chemical</Accordion.Header>
        <Accordion.Body>
          <Chemicaldashboard data={dcs} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default Pulpdashboard;

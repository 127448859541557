import React, { useEffect } from "react";
import { MemoryRouter, Routes, Route } from "react-router-dom";
import { Navbar, Container, Nav } from "react-bootstrap";

import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";

const Navtop = (props) => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <>
      <Navbar
        bg="dark"
        variant="dark"
        onSelect={(selectedKey) => {
          console.log(selectedKey);
          props.page(selectedKey);
        }}
      >
        <Container fluid>
          <Navbar.Brand href="/">Pulp1</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="#dashboard">Dashboard</Nav.Link>
            <Nav.Link href="#chart">Chart</Nav.Link>
            <Nav.Link href="#about">About</Nav.Link>
          </Nav>
          {isAuthenticated ? <SignOutButton /> : <SignInButton />}
        </Container>
      </Navbar>
      {props.children}
    </>
  );
};

export default Navtop;

import React, { useState } from "react";
import { OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import dayjs from "dayjs";
import "./datacard.css";

const Datacard = (props) => {
  const formatDate = props.title.ldate
    ? dayjs(props.title.ldate).format("DD/MM/YYYY HH:mm:ss")
    : "n/a";
  const daydif = props.title.ldate
    ? dayjs().diff(props.title.ldate, "day")
    : "n/a";
  const hourdif = props.title.ldate
    ? dayjs().diff(props.title.ldate, "hour")
    : "n/a";

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="button-tooltip-2">
          {props.title.tagdesc}
          <br />
          ldate: {formatDate}
        </Tooltip>
      }
    >
      <div className={cardBackground(hourdif)}>
        <div className="tagvalue">
          {props.title.value} {props.title.unit}
        </div>
        <div className="tagname">{props.title.tagdesc}</div>
      </div>
    </OverlayTrigger>
  );
};

function cardBackground(hour) {
  if (hour < 2) return "tagcontainer bg-dark bg-gradient";
  if (hour < 24) return "tagcontainer bg-secondary bg-gradient blink_me";
  return "tagcontainer bg-danger bg-gradient blink_me";
}

export default Datacard;

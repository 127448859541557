export const msalConfig = {
  auth: {
    clientId: "dcd8231c-0429-49b3-b9f8-000536b2e51e",
    authority: "https://login.microsoftonline.com/fe4df7ba-6d27-4a6f-9611-b5126d0c908a", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: 'https://pulp1-dashboard.advanceagro.net'
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
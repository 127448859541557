exports.dcsSampleData = [
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411DIG01MODE",
        value: "1.000",
        unit: null,
        tagdesc: "DIGESTER 1 SEQUENCE MODE",
        colnum: 1
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411WI107",
        value: "312.280",
        unit: "t",
        tagdesc: "SIGESTER 1 WEIGHT",
        colnum: 2
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ612",
        value: ".000",
        unit: null,
        tagdesc: "DILUT, LIQ FLOW TO DIGESTERS",
        colnum: 3
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ614WSP01",
        value: "300.000",
        unit: "m3",
        tagdesc: "DIGESTER 1 DPL SETPOINT",
        colnum: 4
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ614-D01",
        value: ".000",
        unit: "m3",
        tagdesc: "DIGESTER 1 DISPL.VOLUME",
        colnum: 5
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411HFACTWSP01",
        value: "491.000",
        unit: null,
        tagdesc: "DIGESTER 1 H-FACTOR SETP.",
        colnum: 6
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411HFACT01",
        value: "274.300",
        unit: null,
        tagdesc: "DIGESTER 1 H-FACTOR",
        colnum: 7
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411COTMPWSP01",
        value: "152.000",
        unit: "deg C",
        tagdesc: "DIGESTER 1 COOK.TEMP SETP.",
        colnum: 8
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411HFACT01TEMP",
        value: "156.770",
        unit: "deg C",
        tagdesc: "DIGESTER 1 COOKING TEMP",
        colnum: 9
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ818WSP01",
        value: "2000.000",
        unit: "m3",
        tagdesc: "DIGESTER 1 HBL SETPOINT",
        colnum: 10
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ818-D01",
        value: "2032.000",
        unit: "m3",
        tagdesc: "DIGESTER 1 HBL VOLUME",
        colnum: 11
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ804WSP01",
        value: "433.000",
        unit: "m3",
        tagdesc: "DIGESTER 1 HWL SETPOINT",
        colnum: 12
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ804-D01",
        value: "449.000",
        unit: "m3",
        tagdesc: "DIGESTER 1 HWL VOLUME",
        colnum: 13
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ804NWSP01",
        value: "144.000",
        unit: "m3",
        tagdesc: "DIG1 TARGET WL TO NEU",
        colnum: 14
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ804N-D01",
        value: "174.000",
        unit: "m3",
        tagdesc: "SUM. HWL. VOLUME TO DIGESTER 1",
        colnum: 15
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411CHF107",
        value: "137.720",
        unit: "t",
        tagdesc: "DIGESTER 1 WEIGHT AFTER CHF",
        colnum: 16
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411TARA107",
        value: "10.000",
        unit: "t",
        tagdesc: "DIGESTER 1 TARED WEIGHT",
        colnum: 17
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411LAH001",
        value: "255.000",
        unit: null,
        tagdesc: "DIGESTER 1 CHIP CHUTE",
        colnum: 18
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411DIG04MODE",
        value: ".020",
        unit: null,
        tagdesc: "DIGESTER 4 SEQUENCE MODE",
        colnum: 19
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411WI407",
        value: "3236.400",
        unit: "t",
        tagdesc: "DIGESTER 4 WEIGHT",
        colnum: 20
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ612-D04",
        value: ".000",
        unit: "m3",
        tagdesc: "DIGESTER 4 DILUT.VOLUME",
        colnum: 21
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ614WSP04",
        value: "300.000",
        unit: "m3",
        tagdesc: "DIGESTER 4 DPL SETPOINT",
        colnum: 22
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ614-D04",
        value: ".000",
        unit: "m3",
        tagdesc: "DIGESTER 4 DISPL.VOLUME",
        colnum: 23
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411HFACTWSP04",
        value: "491.000",
        unit: null,
        tagdesc: "DIGESTER 4 H-FACTOR SETP.",
        colnum: 24
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411HFACT04",
        value: "73.500",
        unit: null,
        tagdesc: "DIGESTER 4 H-FACTOR",
        colnum: 25
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411COTMPWSP04",
        value: "154.000",
        unit: "deg C",
        tagdesc: "DIGESTER 4 COOK.TEMP SETP.",
        colnum: 26
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411HFACT04TEMP",
        value: "150.650",
        unit: "deg C",
        tagdesc: "DIGESTER 4 COOKING TEMP",
        colnum: 27
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ818WSP04",
        value: "200.000",
        unit: "m3",
        tagdesc: "DIGESTER 4 HBL SETPOINT",
        colnum: 28
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ818-D04",
        value: "203.700",
        unit: "m3",
        tagdesc: "DIGESTER 4 HBL VOLUME",
        colnum: 29
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ804WSP04",
        value: "40.000",
        unit: "m3",
        tagdesc: "DIGESTER 4 HWL SETPOINT",
        colnum: 30
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ804-D04",
        value: "40.700",
        unit: "m3",
        tagdesc: "DIGESTER 4 HWL VOLUME",
        colnum: 31
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ804NWSP04",
        value: "12.400",
        unit: "m3",
        tagdesc: "DIG4 TARGET WL TO NEU",
        colnum: 32
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ804N-D04",
        value: "16.100",
        unit: "m3",
        tagdesc: "SUM. HWL. VOLUME TO DIGESTER 4",
        colnum: 33
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411CHF407",
        value: "119.330",
        unit: "t",
        tagdesc: "DIGESTER 4 WEIGHT AFTER CHF",
        colnum: 34
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411TARA407",
        value: "7.050",
        unit: "t",
        tagdesc: "DIGESTER 4 TARED WEIGHT",
        colnum: 35
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411LAH006",
        value: "255.000",
        unit: null,
        tagdesc: "DIGESTER 4 CHIP CHUTE",
        colnum: 36
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411DIG02MODE",
        value: ".020",
        unit: null,
        tagdesc: "DIGESTER 2 SEQUENCE MODE",
        colnum: 37
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411WI207",
        value: "1906.800",
        unit: "t",
        tagdesc: "DIGESTER 2 WEIGHT",
        colnum: 38
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ612-D02",
        value: ".000",
        unit: "m3",
        tagdesc: "DIGESTER 2 DILUT.VOLUME",
        colnum: 39
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ614WSP02",
        value: "300.000",
        unit: "m3",
        tagdesc: "DIGESTER 2 DPL SETPOINT",
        colnum: 40
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ614-D02",
        value: ".000",
        unit: "m3",
        tagdesc: "DIGESTER 2 DISPL.VOLUME",
        colnum: 41
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411HFACTWSP02",
        value: "491.000",
        unit: null,
        tagdesc: "DIGESTER 2 H-FACTOR SETP.",
        colnum: 42
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411HFACT02",
        value: ".000",
        unit: null,
        tagdesc: "DIGESTER 2 H-FACTOR",
        colnum: 43
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411COTMPWSP02",
        value: "154.000",
        unit: "deg C",
        tagdesc: "DIGESTER 2 COOK.TEMP SETP.",
        colnum: 44
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411HFACT02TEMP",
        value: "1010.200",
        unit: "deg C",
        tagdesc: "DIGESTER 2 COOKING TEMP",
        colnum: 45
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ818WSP02",
        value: "200.000",
        unit: "m3",
        tagdesc: "DIGESTER 2 HBL SETPOINT",
        colnum: 46
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ818-D02",
        value: "7.700",
        unit: "m3",
        tagdesc: "DIGESTER 2 HBL VOLUME",
        colnum: 47
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ804WSP02",
        value: "92.800",
        unit: "m3",
        tagdesc: "DIGESTER 2 HWL SETPOINT",
        colnum: 48
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ804-D02",
        value: ".000",
        unit: "m3",
        tagdesc: "DIGESTER 2 HWL VOLUME",
        colnum: 49
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ804NWSP02",
        value: "15.100",
        unit: "m3",
        tagdesc: "DIG2 TARGET WL TO NEU",
        colnum: 50
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ804N-D02",
        value: "18.400",
        unit: "m3",
        tagdesc: "SUM. HWL. VOLUME TO DIGESTER 2",
        colnum: 51
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411CHF207",
        value: "141.620",
        unit: "t",
        tagdesc: "DIGESTER 2 WEIGHT AFTER CHF",
        colnum: 52
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411TARA207",
        value: ".000",
        unit: "t",
        tagdesc: "DIGESTER 2 TARED WEIGHT",
        colnum: 53
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411LAH002",
        value: "255.000",
        unit: null,
        tagdesc: "DIGESTER 2 CHIP CHUTE",
        colnum: 54
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411DIG05MODE",
        value: ".020",
        unit: null,
        tagdesc: "DIGESTER 5 SEQUENCE MODE",
        colnum: 55
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411WI507",
        value: "471.700",
        unit: "t",
        tagdesc: "DIGESTER 5 WEIGHT",
        colnum: 56
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ612-D05",
        value: ".000",
        unit: "m3",
        tagdesc: "DIGESTER 5 DILUT.VOLUME",
        colnum: 57
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ614WSP05",
        value: "300.000",
        unit: "m3",
        tagdesc: "DIGESTER 5 DPL SETPOINT",
        colnum: 58
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ614-D05",
        value: ".000",
        unit: "m3",
        tagdesc: "DIGESTER 5 DISPL.VOLUME",
        colnum: 59
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411HFACTWSP05",
        value: "489.000",
        unit: null,
        tagdesc: "DIGESTER 5 H-FACTOR SETP.",
        colnum: 60
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411HFACT05",
        value: ".000",
        unit: null,
        tagdesc: "DIGESTER 5 H-FACTOR",
        colnum: 61
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411COTMPWSP05",
        value: "154.000",
        unit: "deg C",
        tagdesc: "DIGESTER 5 COOK.TEMP SETP.",
        colnum: 62
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411HFACT05TEMP",
        value: "810.800",
        unit: "deg C",
        tagdesc: "DIGESTER 5 COOKING TEMP",
        colnum: 63
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ818WSP05",
        value: "200.000",
        unit: "m3",
        tagdesc: "DIGESTER 5 HBL SETPOINT",
        colnum: 64
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ818-D05",
        value: ".000",
        unit: "m3",
        tagdesc: "DIGESTER 5 HBL VOLUME",
        colnum: 65
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ804WSP05",
        value: "92.000",
        unit: "m3",
        tagdesc: "DIGESTER 5 HWL SETPOINT",
        colnum: 66
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ804-D05",
        value: ".000",
        unit: "m3",
        tagdesc: "DIGESTER 5 HWL VOLUME",
        colnum: 67
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ804NWSP05",
        value: "14.900",
        unit: "m3",
        tagdesc: "DIG5 TARGET WL TO NEU",
        colnum: 68
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ804N-D05",
        value: ".000",
        unit: "m3",
        tagdesc: "SUM. HWL. VOLUME TO DIGESTER 5",
        colnum: 69
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411CHF507",
        value: "44.630",
        unit: "t",
        tagdesc: "DIGESTER 5 WEIGHT AFTER CHF",
        colnum: 70
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411TARA507",
        value: "1.570",
        unit: "t",
        tagdesc: "DIGESTER 5 TARED WEIGHT",
        colnum: 71
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411LAH009",
        value: "255.000",
        unit: null,
        tagdesc: "DIGESTER 5 CHIP CHUTE",
        colnum: 72
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411DIG03MODE",
        value: ".020",
        unit: null,
        tagdesc: "DIGESTER 3 SEQUENCE MODE",
        colnum: 73
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411WI307",
        value: "3612.600",
        unit: "t",
        tagdesc: "DIGESTER 3 WEIGHT",
        colnum: 74
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ612-D03",
        value: ".000",
        unit: "m3",
        tagdesc: "DIGESTER 3 DILUT.VOLUME",
        colnum: 75
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ614WSP03",
        value: "300.000",
        unit: "m3",
        tagdesc: "DIGESTER 3 DPL SETPOINT",
        colnum: 76
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ614-D03",
        value: "59.500",
        unit: "m3",
        tagdesc: "DIGESTER 3 DISPL.VOLUME",
        colnum: 77
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411HFACTWSP03",
        value: "492.000",
        unit: null,
        tagdesc: "DIGESTER 3 H-FACTOR SETP.",
        colnum: 78
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411HFACT03",
        value: "55.430",
        unit: null,
        tagdesc: "DIGESTER 3 H-FACTOR",
        colnum: 79
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411COTMPWSP03",
        value: "154.000",
        unit: "deg C",
        tagdesc: "DIGESTER 3 COOK.TEMP SETP.",
        colnum: 80
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411HFACT03TEMP",
        value: "1392.200",
        unit: "deg C",
        tagdesc: "DIGESTER 3 COOKING TEMP",
        colnum: 81
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ818WSP03",
        value: "200.000",
        unit: "m3",
        tagdesc: "DIGESTER 3 HBL SETPOINT",
        colnum: 82
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ818-D03",
        value: "202.600",
        unit: "m3",
        tagdesc: "DIGESTER 3 HBL VOLUME",
        colnum: 83
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ804WSP03",
        value: "40.000",
        unit: "m3",
        tagdesc: "DIGESTER 3 HWL SETPOINT",
        colnum: 84
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ804-D03",
        value: "40.000",
        unit: "m3",
        tagdesc: "DIGESTER 3 HWL VOLUME",
        colnum: 85
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ804NWSP03",
        value: "13.500",
        unit: "m3",
        tagdesc: "DIG3 TARGET WL TO NEU",
        colnum: 86
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411FQ804N-D03",
        value: "16.900",
        unit: "m3",
        tagdesc: "SUM. HWL. VOLUME TO DIGESTER 3",
        colnum: 87
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411CHF307",
        value: "121.890",
        unit: "t",
        tagdesc: "DIGESTER 3 WEIGHT AFTER CHF",
        colnum: 88
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411TARA307",
        value: "10.000",
        unit: "t",
        tagdesc: "DIGESTER 3 TARED WEIGHT",
        colnum: 89
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411LAH004",
        value: "-1.000",
        unit: null,
        tagdesc: "DIGESTER 3 CHIP CHUTE",
        colnum: 90
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411TI601",
        value: "213.430",
        unit: "deg C",
        tagdesc: "MP STEAM MAIN LINE TEMP",
        colnum: 91
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411PI602",
        value: "655.350",
        unit: "barE",
        tagdesc: "MP STEAM MAIN LINE PRESS.",
        colnum: 92
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411LI609",
        value: "655.350",
        unit: "%",
        tagdesc: "DPL LIQUOR TANK LEVEL",
        colnum: 93
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411LI618",
        value: "655.350",
        unit: "%",
        tagdesc: "WARM BLACK LIQ. TANK LEVEL",
        colnum: 94
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411LC625",
        value: "62.260",
        unit: "%",
        tagdesc: "WHITE LIQUOR TO WHT LIQ.TANK",
        colnum: 95
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411LC703.1",
        value: ".000",
        unit: "%",
        tagdesc: "CONDENSATE LEVEL IN COND. TANK",
        colnum: 96
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411LI803",
        value: "64.070",
        unit: "%",
        tagdesc: "WHITE LIQUOR ACC LEVEL IN ACC",
        colnum: 97
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411COUNDIG5",
        value: "1.000",
        unit: null,
        tagdesc: "ALARM COUNTER DIGESTER5",
        colnum: 98
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411PC805.1",
        value: ".000",
        unit: "barE",
        tagdesc: "WHITE LIQUOR ACC PRESS.CONTROL",
        colnum: 99
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "321LI103",
        value: "84.370",
        unit: "%",
        tagdesc: "SURGE BIN SILO LEVEL",
        colnum: 100
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411LI807",
        value: "57.980",
        unit: "%",
        tagdesc: "HBL ACC 2 LEVEL IN ACCUMUL",
        colnum: 101
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "451AIA-802",
        value: "16.130",
        unit: "%",
        tagdesc: "MOAVG WL SBL IDS",
        colnum: 102
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411PC809.1",
        value: ".000",
        unit: "barE",
        tagdesc: "HBL ACC 2 PRESSURE CONTROL",
        colnum: 103
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "321II-C021-1",
        value: "655.350",
        unit: "%",
        tagdesc: "BELT CONVEYOR CHIPS TO DIGESTE",
        colnum: 104
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411LI817",
        value: "655.350",
        unit: "%",
        tagdesc: "HBL ACC 1 LEVEL IN ACC",
        colnum: 105
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411PC820.1",
        value: ".000",
        unit: "barE",
        tagdesc: "HBL ACC 1 PRESSURE CONTROL",
        colnum: 106
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411LI904",
        value: "67.710",
        unit: "%",
        tagdesc: "LIQUOR FILTER VAT LEVEL",
        colnum: 107
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411LC913",
        value: "63.710",
        unit: "%",
        tagdesc: "HOT WATER TO HOT WAT.TANK",
        colnum: 108
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411LI-659",
        value: "52.850",
        unit: "%",
        tagdesc: "WARM BLACK LIQ TANK LEVEL",
        colnum: 109
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "471LI-190",
        value: "655.350",
        unit: "%",
        tagdesc: "WH LIQ STORAGE TANK",
        colnum: 110
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431FC-006",
        value: "47.900",
        unit: "l/s",
        tagdesc: "WASH PRESS E001 PULP FEED",
        colnum: 111
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411QC855",
        value: "28.990",
        unit: "g/l",
        tagdesc: "CONDUCTIVITY ALKALI TO ACC1",
        colnum: 112
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "451LI-805",
        value: "88.360",
        unit: "%",
        tagdesc: "LVL WLIQ STRG TK#1",
        colnum: 113
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421LI-001",
        value: "655.350",
        unit: "%",
        tagdesc: "DISCHARGETANK009LEVEL",
        colnum: 114
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "411PI923.1",
        value: ".000",
        unit: "min",
        tagdesc: "DISCHARGE LINE PRESSURE EXCEED",
        colnum: 115
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421FC-409",
        value: "94.320",
        unit: "l/s",
        tagdesc: "O2-REACTOR PULP FEED",
        colnum: 116
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421TC-410",
        value: "83.830",
        unit: "deg C",
        tagdesc: "PULP TO REACTOR TEMPERATURE",
        colnum: 117
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421QC-004",
        value: "5.110",
        unit: "%",
        tagdesc: "PRIMARY KNOTTER PULP CONSISTENCY",
        colnum: 118
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421FC-312",
        value: "103.350",
        unit: "l/s",
        tagdesc: "PREO2PRESS E002 PULP FEED",
        colnum: 119
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421FC-313",
        value: "96.860",
        unit: "l/s",
        tagdesc: "PREO2PRESS E002 PULP FEED",
        colnum: 120
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421LC-316",
        value: "82.150",
        unit: "%",
        tagdesc: "FILTRATETANK 003LEVEL CONTROL",
        colnum: 121
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421PC-324",
        value: "115.750",
        unit: "barE",
        tagdesc: "PREO2 PRESS E002HYDROIL PRESSURE",
        colnum: 122
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421LC-017",
        value: "24.540",
        unit: "%",
        tagdesc: "KNOT SEPARATOR LIQUID LEVEL",
        colnum: 123
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421PC-020",
        value: "3.780",
        unit: "barE",
        tagdesc: "FEED PUMP P005 CONTROL VALVE",
        colnum: 124
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421PDI-103",
        value: ".180",
        unit: "barE",
        tagdesc: "LIGSCREEN E008 PRESSURE DIFF",
        colnum: 125
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421PC-430",
        value: "6.690",
        unit: "barE",
        tagdesc: "O2-REACTOR PRESSURE",
        colnum: 126
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421FC-107",
        value: "100.120",
        unit: "l/s",
        tagdesc: "WASH PRESS E001 PULP FEED",
        colnum: 127
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421FC-108",
        value: "101.520",
        unit: "l/s",
        tagdesc: "WASH PRESS E001 PULP FEED",
        colnum: 128
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421LC-110",
        value: "59.740",
        unit: "%",
        tagdesc: "FILTRATETANK 002LEVEL CONTROL",
        colnum: 129
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421LI-501",
        value: "65.130",
        unit: "%",
        tagdesc: "BLOW TANK LEVEL",
        colnum: 130
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421QC-205",
        value: "4.520",
        unit: "%",
        tagdesc: "PRIMARY SCREEN FEED CONSISTENCY",
        colnum: 131
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421PC-120",
        value: "123.300",
        unit: "barE",
        tagdesc: "WASH PRESS E001 HYDROIL PRESSURE",
        colnum: 132
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421QC-507",
        value: "5.060",
        unit: "%",
        tagdesc: "POSTO2PRESS E003PULP CONSISTENCY",
        colnum: 133
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421FC-511",
        value: "84.250",
        unit: "l/s",
        tagdesc: "POSTO2PRESS E003PULP FEED",
        colnum: 134
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421FC-512",
        value: "86.660",
        unit: "l/s",
        tagdesc: "POSTO2PRESS E003PULP FEED",
        colnum: 135
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421PDI-220",
        value: ".140",
        unit: "barE",
        tagdesc: "TERTIARY SCREEN PRESSURE DIFF",
        colnum: 136
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421LC-519",
        value: "68.210",
        unit: "%",
        tagdesc: "FILTRATETANK 004LEVEL CONTROL",
        colnum: 137
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421PDI-210",
        value: ".130",
        unit: "barE",
        tagdesc: "PRIMARY SCREEN PRESSURE DIFF",
        colnum: 138
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421PDI-216",
        value: ".230",
        unit: "barE",
        tagdesc: "SECONDARY SCREENPRESSURE DIFF",
        colnum: 139
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421WI-E012-1",
        value: "27.930",
        unit: "%",
        tagdesc: "SCREW PRESS E012TORQUE MEASUREME",
        colnum: 140
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421PC-527",
        value: "97.510",
        unit: "barE",
        tagdesc: "POST02PRESS E003HYDROIL PRESSURE",
        colnum: 141
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421LI-201",
        value: "73.280",
        unit: "%",
        tagdesc: "LC-TANK T009 LEVEL",
        colnum: 142
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421LC-230",
        value: "62.540",
        unit: "%",
        tagdesc: "REJECT TANK T018LEVEL",
        colnum: 143
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421LC-301-1",
        value: "52.080",
        unit: "%",
        tagdesc: "REJECT TANK T010LEVEL CONTROL",
        colnum: 144
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421LC-301-2",
        value: "52.080",
        unit: "%",
        tagdesc: "REJECT TANK T010LEVEL CONTROL",
        colnum: 145
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421II-E004-1",
        value: "64.260",
        unit: "%",
        tagdesc: "PRIMARY KNOTTER",
        colnum: 146
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421II-E005-1",
        value: "78.010",
        unit: "%",
        tagdesc: "SECONDARY KNOTTER",
        colnum: 147
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421FC-541",
        value: "54.170",
        unit: "l/s",
        tagdesc: "POSTO2PRESS E003DILSCREW DILUT",
        colnum: 148
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421FC-338",
        value: "47.820",
        unit: "l/s",
        tagdesc: "PREO2 PRESS E002DILSCREW DILUT",
        colnum: 149
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421LI-401",
        value: "87.220",
        unit: "%",
        tagdesc: "MC-TANK T006 LEVEL",
        colnum: 150
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421QC-412",
        value: "10.320",
        unit: "%",
        tagdesc: "PULP TO REACTOR CONSISTENCY",
        colnum: 151
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421II-C001-1",
        value: "50.470",
        unit: "%",
        tagdesc: "CURRENT WASH PRESS E001",
        colnum: 152
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421II-E001-1",
        value: "74.890",
        unit: "%",
        tagdesc: "BRN STK WASH PRESS DISCHARGER",
        colnum: 153
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421FC-405",
        value: ".900",
        unit: "l/s",
        tagdesc: "OW-LIQUOR PIPE FLOW",
        colnum: 154
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421II-E009-1",
        value: "73.840",
        unit: "%",
        tagdesc: "PRIMARY SCREEN",
        colnum: 155
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421II-E010-1",
        value: "54.190",
        unit: "%",
        tagdesc: "SECONDARY SCREEN",
        colnum: 156
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421LC-603.1",
        value: ".000",
        unit: "%",
        tagdesc: "STAND PIPE T014 LEVEL",
        colnum: 157
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421II-E011-1",
        value: "41.460",
        unit: "%",
        tagdesc: "TERTIARY SCREEN",
        colnum: 158
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421LC-603.2",
        value: ".000",
        unit: "%",
        tagdesc: "STAND PIPE T014 BOTTOM DILUTION",
        colnum: 159
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421II-P015.1",
        value: ".000",
        unit: "%",
        tagdesc: "CURRENT SCREEN PIT DISC. PUMP",
        colnum: 160
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421II-C002-1",
        value: "62.460",
        unit: "%",
        tagdesc: "CURRENT PREO2 PRESS E002",
        colnum: 161
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421II-E002-1",
        value: "57.700",
        unit: "%",
        tagdesc: "PRE-02 WASH PRESS DISCHARGER",
        colnum: 162
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421QI-205.2",
        value: ".000",
        unit: "AD t/d",
        tagdesc: "FLOW",
        colnum: 163
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421II-P015.2",
        value: ".000",
        unit: "%",
        tagdesc: "SCREENING PIT DISCHARGE PUMP CURRENT",
        colnum: 164
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421QI-341",
        value: "655.350",
        unit: null,
        tagdesc: "KAPPA PRE-O2",
        colnum: 165
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421II-C003-1",
        value: "39.870",
        unit: "%",
        tagdesc: "CURRENT POSTO2 PRESS E003",
        colnum: 166
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421QI-004.4",
        value: ".000",
        unit: "AD t/d",
        tagdesc: "PRODUCTION ADT/d",
        colnum: 167
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421QI-412-4",
        value: "929.000",
        unit: "AD t/d",
        tagdesc: "PRODUCTION ADT/d",
        colnum: 168
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421II-E003-1",
        value: "64.140",
        unit: "%",
        tagdesc: "POSTO2PRESS E003DISCHARGER",
        colnum: 169
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421QI-542",
        value: "655.350",
        unit: null,
        tagdesc: "KAPPA POST-O2",
        colnum: 170
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421FI-418-7",
        value: "10.960",
        unit: "kg/ADt",
        tagdesc: "O2 DOSE kg/ADt",
        colnum: 171
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421PI-451",
        value: "13.590",
        unit: "barE",
        tagdesc: "OXYGEN PRESSURE INDICATOR",
        colnum: 172
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421XI-466",
        value: ".540",
        unit: "mm/s",
        tagdesc: "STEAM HEATER VIBRATION INDICATOR",
        colnum: 173
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421PC-478",
        value: "3.340",
        unit: "barE",
        tagdesc: "REACTOR 2 PRESSURE CONTROL",
        colnum: 174
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421QI-002",
        value: "12.610",
        unit: null,
        tagdesc: "KAPPA DISCHARGE",
        colnum: 175
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421TC-473",
        value: "98.090",
        unit: "deg C",
        tagdesc: "PULP TEMPERATURE CONTROL",
        colnum: 176
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "421FI-457-1",
        value: "9.200",
        unit: "kg/ADt",
        tagdesc: "NULL",
        colnum: 177
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431FC-007",
        value: "47.470",
        unit: "l/s",
        tagdesc: "WASH PRESS E001 PULP FEED",
        colnum: 178
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431LC-010",
        value: "44.810",
        unit: "%",
        tagdesc: "PRESSATETANK 006LEVEL",
        colnum: 179
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431PC-016",
        value: "119.110",
        unit: "barE",
        tagdesc: "WASH PRESS E001 HYDROIL PRESSURE",
        colnum: 180
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431FC-031",
        value: "56.210",
        unit: "l/s",
        tagdesc: "WASH PRESS E001 DILSCREW DILUT",
        colnum: 181
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431LC-103.1",
        value: ".000",
        unit: "%",
        tagdesc: "STAND PIPE T017 LEVEL",
        colnum: 182
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431QI-115-7",
        value: "779.300",
        unit: "AD t/d",
        tagdesc: "PRODUCTION ADT/d",
        colnum: 183
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431QI-117",
        value: ".000",
        unit: "pH",
        tagdesc: "DO-TOWER T001 PH",
        colnum: 184
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431LC-118",
        value: "64.390",
        unit: "%",
        tagdesc: "DO-PULPTANK T022LEVEL",
        colnum: 185
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431QC-120",
        value: "5.110",
        unit: "%",
        tagdesc: "WASH PRESS E002 PULP CONSISTENCY",
        colnum: 186
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431PDI-131",
        value: ".010",
        unit: "barE",
        tagdesc: "ALKPRESS SCREEN PRESSURE DIFF",
        colnum: 187
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431FI-132",
        value: "2.280",
        unit: "l/s",
        tagdesc: "BLEACHING PIT CANAL FLOW",
        colnum: 188
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431LC-135",
        value: "43.540",
        unit: "%",
        tagdesc: "DO-TOWER T001 CHUTE LEVEL",
        colnum: 189
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431PDI-139",
        value: ".040",
        unit: "barE",
        tagdesc: "ACIDPRESS SCREENPRESSURE DIFF",
        colnum: 190
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431QI-115-10",
        value: "31.890",
        unit: null,
        tagdesc: "D0 CLO2 DOSE",
        colnum: 191
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431FC-201",
        value: "82.290",
        unit: "l/s",
        tagdesc: "WASH PRESS E002 PULP FEED",
        colnum: 192
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431LI-003",
        value: "655.350",
        unit: "%",
        tagdesc: "HD-TOWER 421T005LEVEL",
        colnum: 193
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431FC-204",
        value: "58.780",
        unit: "l/s",
        tagdesc: "WASH PRESS E002 PULP FEED",
        colnum: 194
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431PC-214",
        value: "122.960",
        unit: "barE",
        tagdesc: "WASHPRESS E002 HYDROIL PRESSURE",
        colnum: 195
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431FC-226",
        value: ".450",
        unit: "l/s",
        tagdesc: "NAOH PIPE FLOW",
        colnum: 196
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431FC-227",
        value: "48.060",
        unit: "l/s",
        tagdesc: "WASHPRESS E002 DILSCREW DILUT",
        colnum: 197
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431LC-303.1",
        value: ".000",
        unit: "%",
        tagdesc: "STAND PIPE T018 LEVEL",
        colnum: 198
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431PC-311",
        value: "11.510",
        unit: "barE",
        tagdesc: "OXYGEN GAS PIPE PRESSURE",
        colnum: 199
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431TC-312",
        value: "80.770",
        unit: "deg C",
        tagdesc: "PULPTO EOP-TOWERTEMPERATURE",
        colnum: 200
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431QC-313",
        value: "9.990",
        unit: "pH",
        tagdesc: "PULPTO EOP-TOWERPH",
        colnum: 201
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431QI-316",
        value: "12.140",
        unit: "pH",
        tagdesc: "EOP-TOWER T003 PH",
        colnum: 202
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431C002-1",
        value: "255.000",
        unit: null,
        tagdesc: "WASH PRESS E002 DILUTION SCREW",
        colnum: 203
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431II-E012-1",
        value: "41.500",
        unit: "%",
        tagdesc: "D0 TOWER SCRAPER",
        colnum: 204
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431II-P001-1",
        value: "65.560",
        unit: "%",
        tagdesc: "D0 MC-PUMP",
        colnum: 205
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431II-P003-1",
        value: "51.470",
        unit: "%",
        tagdesc: "EOP MC-PUMP",
        colnum: 206
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431LC-206",
        value: "77.020",
        unit: "%",
        tagdesc: "PRESSATETANK 007LEVEL",
        colnum: 207
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431II-E002-1",
        value: "55.660",
        unit: "%",
        tagdesc: "D0 WASH PRESS DISCHARGER",
        colnum: 208
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431FI-307.2",
        value: ".000",
        unit: "kg/ADt",
        tagdesc: "H2O2 DOSE kg ACT / ADt",
        colnum: 209
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431QC-040",
        value: "705.600",
        unit: "ADT",
        tagdesc: "WHITE WATER CONDUCT",
        colnum: 210
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431II-C001-1",
        value: "39.240",
        unit: "%",
        tagdesc: "CURRENT WASH PRESS E001",
        colnum: 211
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431II-E001-1",
        value: "50.530",
        unit: "%",
        tagdesc: "WASH PRESS E001 DISCHARGER",
        colnum: 212
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431FC-423.1",
        value: ".000",
        unit: "l/h",
        tagdesc: "ACID FLOW AT PRE-BLEACH",
        colnum: 213
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431FC-423.2",
        value: ".000",
        unit: "l/h",
        tagdesc: "ACID FLOW AT EOP",
        colnum: 214
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431QI-310",
        value: "6.660",
        unit: "kg/ADt",
        tagdesc: "REAL O2 DOSE kg/ADt",
        colnum: 215
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431QC-114",
        value: "4.390",
        unit: "pH",
        tagdesc: "PULP TO DO-TOWERPH",
        colnum: 216
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431QC-078",
        value: "8.520",
        unit: "%",
        tagdesc: "PULP CONSISTENCY",
        colnum: 217
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431LC-320",
        value: "72.170",
        unit: "%",
        tagdesc: "EOP-TOWER T003 LEVEL",
        colnum: 218
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431QC-322",
        value: "5.230",
        unit: "%",
        tagdesc: "DEWATPRESS E003 PULP CONSISTENCY",
        colnum: 219
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431FC-321",
        value: "141.830",
        unit: "l/s",
        tagdesc: "DEWATPRESS E003 PULP FEED",
        colnum: 220
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431II-E003-1",
        value: "50.640",
        unit: "%",
        tagdesc: "EOP DEWATERING PRESS DISCHARGER",
        colnum: 221
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431PC-333",
        value: "121.940",
        unit: "barE",
        tagdesc: "DEWATPRESS E003 HYDROIL PRESSURE",
        colnum: 222
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431II-C003-1",
        value: "54.910",
        unit: "%",
        tagdesc: "CURRENT DEWAT PRESS E003",
        colnum: 223
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431FC-339",
        value: "46.470",
        unit: "l/s",
        tagdesc: "DEWATPRESS E003 DILSCREW DILUT",
        colnum: 224
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431LC-324",
        value: "79.590",
        unit: "%",
        tagdesc: "PRESSATETANK 008LEVEL",
        colnum: 225
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431LC-403.1",
        value: ".000",
        unit: "%",
        tagdesc: "STAND PIPE T019 LEVEL",
        colnum: 226
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431II-P005-1",
        value: "68.890",
        unit: "%",
        tagdesc: "D1 MC-PUMP",
        colnum: 227
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431FI-407-5",
        value: "1.380",
        unit: "kg/ADt",
        tagdesc: "ClO2 DOSE kg ACTCl/ADT",
        colnum: 228
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431QC-410",
        value: "4.480",
        unit: "pH",
        tagdesc: "PULP TO D1-TOWERPH",
        colnum: 229
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431TC-409",
        value: "154.000",
        unit: "deg C",
        tagdesc: "PULP TO D1-TOWERTEMPERATURE",
        colnum: 230
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431LC-415",
        value: "83.730",
        unit: "%",
        tagdesc: "D1-TOWER T005 LEVEL",
        colnum: 231
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431QI-414",
        value: "68.300",
        unit: "pH",
        tagdesc: "D1-TOWER T005 PH",
        colnum: 232
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431QC-419",
        value: "1.950",
        unit: "%",
        tagdesc: "DEWATPRESS E004 PULP CONSISTENCY",
        colnum: 233
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431FC-418",
        value: "4.800",
        unit: "l/s",
        tagdesc: "DEWATPRESS E004 PULP FEED",
        colnum: 234
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431II-E004-1",
        value: "122.510",
        unit: "%",
        tagdesc: "DEWATPRESS E004 DISCHARGER",
        colnum: 235
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431PC-429",
        value: "37.350",
        unit: "barE",
        tagdesc: "D1 DEWATERPRESS HYDROIL PRESSURE",
        colnum: 236
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431II-C004-1",
        value: "104.710",
        unit: "%",
        tagdesc: "CURRENT DEWAT PRESS E004",
        colnum: 237
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431FC-440",
        value: "39.520",
        unit: "l/s",
        tagdesc: "DEWATPRESS E004 DILSCREW DILUT",
        colnum: 238
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431LC-434",
        value: "173.850",
        unit: "%",
        tagdesc: "PRESSATETANK 009LEVEL",
        colnum: 239
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431LC-503",
        value: "73.810",
        unit: "%",
        tagdesc: "CLEAN LC-TANK LEVEL",
        colnum: 240
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431QC-042",
        value: "65.060",
        unit: "pH",
        tagdesc: "pH LC TANK",
        colnum: 241
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431PI-033",
        value: "5.020",
        unit: "barE",
        tagdesc: "INSTRUMENT AIR PRESSURE",
        colnum: 242
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431LI-309",
        value: "5.220",
        unit: "%",
        tagdesc: "LEVEL H2O2 TANK1",
        colnum: 243
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431QC-502",
        value: "37.780",
        unit: "%",
        tagdesc: "COARSE SCREEN PULP CONSISTENCY",
        colnum: 244
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431LC-534",
        value: "1.970",
        unit: "%",
        tagdesc: "FILTRATETANK 010LEVEL",
        colnum: 245
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431II-E005-1",
        value: "79.810",
        unit: "%",
        tagdesc: "PRE-DECKER",
        colnum: 246
    },
    {
        rindex: 12471,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431II-E006-1",
        value: "47.960",
        unit: "%",
        tagdesc: "DECKER E006 DRUM",
        colnum: 247
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431LC-538",
        value: "45.890",
        unit: "%",
        tagdesc: "DECKER E006 VAT LEVEL",
        colnum: 248
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431LC-603.1",
        value: ".000",
        unit: "%",
        tagdesc: "STAND PIPE T020 LEVEL",
        colnum: 249
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431II-P007-1",
        value: "24.740",
        unit: "%",
        tagdesc: "BLEACHED MC-PUMP",
        colnum: 250
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431LI-607",
        value: "655.350",
        unit: "%",
        tagdesc: "BLCH HD-TOWER 1 LEVEL",
        colnum: 251
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431LI-610",
        value: "655.350",
        unit: "%",
        tagdesc: "BLCH HD-TOWER 2 LEVEL",
        colnum: 252
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431QI-646",
        value: "3.260",
        unit: "%",
        tagdesc: "CONSIST",
        colnum: 253
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431FI-647",
        value: "311.420",
        unit: "l/s",
        tagdesc: "FLOW",
        colnum: 254
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431LC-618",
        value: "82.030",
        unit: "%",
        tagdesc: "WHITE WATER TANKLEVEL",
        colnum: 255
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431FQ-621",
        value: "981.000",
        unit: "AD t/d",
        tagdesc: "BLEACHED PULP PRODUCTION",
        colnum: 256
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "611LI-132",
        value: "73.770",
        unit: "%",
        tagdesc: "CHLORATE STRG TKLEVEL RECORD",
        colnum: 257
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "611LI-160",
        value: "65.620",
        unit: "%",
        tagdesc: "ACID STRG TANK LEVEL RECORD",
        colnum: 258
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "611LI-170",
        value: "73.760",
        unit: "%",
        tagdesc: "METHANOL STRG TKLEVEL RECORD",
        colnum: 259
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "611LI-180",
        value: "54.210",
        unit: "%",
        tagdesc: "50% NaOH STRG TKLEVEL INDICATOR",
        colnum: 260
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "611LC-190",
        value: "64.430",
        unit: "%",
        tagdesc: "200GPL NaOH TANKLEVEL CONTROL",
        colnum: 261
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "621FI-299",
        value: "655.350",
        unit: "l/s",
        tagdesc: "CLO2-WATER CONSUMPTION RATE",
        colnum: 262
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "622LI-220",
        value: "57.340",
        unit: "%",
        tagdesc: "SVP GENERATOR LEVEL RECORD",
        colnum: 263
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "622PI-224",
        value: "64701.000",
        unit: "mbar",
        tagdesc: "CLO2-GAS GEN. PRESSURE IND.",
        colnum: 264
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "622FC-263",
        value: "2142.200",
        unit: "kg/hr",
        tagdesc: "CHLORATE FEED FLOW CONTROL",
        colnum: 265
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "622FC-266",
        value: "312.300",
        unit: "l/h",
        tagdesc: "ACID FEED FLOW CONTROL",
        colnum: 266
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "602TC-112",
        value: "15.830",
        unit: "deg C",
        tagdesc: "CHILLED WATER TEMP CONTROL",
        colnum: 267
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "602PI-116",
        value: "64632.000",
        unit: "mbar",
        tagdesc: "CHILLER UNIT PRESS INDICATOR",
        colnum: 268
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "622FC-267",
        value: "138.660",
        unit: "kg/hr",
        tagdesc: "METHANOL FEED FLOW CONTROL",
        colnum: 269
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "622PI-273",
        value: "11.100",
        unit: "barE",
        tagdesc: "STEAM TO EJECTORPRESSURE RECORD",
        colnum: 270
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "623FI133",
        value: "1151.000",
        unit: "l/m",
        tagdesc: "R8 CLO2 TO STOR FLOW",
        colnum: 271
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "623TI124",
        value: "13.860",
        unit: "DEG C",
        tagdesc: "R8 CHILLED WATERTEMPERATURE",
        colnum: 272
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "623AIC127",
        value: "10.130",
        unit: "GPL",
        tagdesc: "R8 CLO2 TO STOR STRENGTH",
        colnum: 273
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "623FIC031",
        value: "9.750",
        unit: "l/m",
        tagdesc: "R8 METHANOL DILUTION FLOW",
        colnum: 274
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "623PIC062",
        value: "116.940",
        unit: "mmHGA",
        tagdesc: "R8 GENERATOR PRESSURE",
        colnum: 275
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "623FIC052",
        value: "35.060",
        unit: "l/m",
        tagdesc: "R8 GENERATOR CHLORATE FLOW",
        colnum: 276
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "622QI-283",
        value: "9.490",
        unit: "g/l",
        tagdesc: "CLO2 ABS TOWER CLO2 ANALYZER",
        colnum: 277
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "622FI-285",
        value: "655.350",
        unit: "l/s",
        tagdesc: "CLO2-WATER PROD.FLOW RECORD",
        colnum: 278
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "622LI-291",
        value: "58.780",
        unit: "%",
        tagdesc: "CLO2-WTR STRG #1LEVEL RECORD",
        colnum: 279
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "623FIC050",
        value: "8.000",
        unit: "l/m",
        tagdesc: "R8 GENERATOR ACID FLOW",
        colnum: 280
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "623FI391",
        value: "44.950",
        unit: "l/s",
        tagdesc: "MILL WATER FLOW",
        colnum: 281
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "623FI395",
        value: "2873.000",
        unit: "kg/hr",
        tagdesc: "LP STEAM TO CHILLER FLOW",
        colnum: 282
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "621LI-291",
        value: "55.020",
        unit: "%",
        tagdesc: "CLO2-WTR STRG #1LEVEL RECORD",
        colnum: 283
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "621LI-292",
        value: "655.350",
        unit: "%",
        tagdesc: "CLO2-WTR STRG #2LEVEL RECORD",
        colnum: 284
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "622LI-292",
        value: "59.260",
        unit: "%",
        tagdesc: "CLO2-WTR STRG #2LEVEL RECORD",
        colnum: 285
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "622FI-289",
        value: "655.350",
        unit: "l/s",
        tagdesc: "CLO2-WATER PROD.FLOW TO PLUP#2",
        colnum: 286
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "611LI-131",
        value: "73.450",
        unit: "%",
        tagdesc: "CHLORATE DISS. TK LEVEL IND.",
        colnum: 287
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "602PI-115",
        value: "4.050",
        unit: "barE",
        tagdesc: "PRESSURE LP STEAM TO CHILLER#2",
        colnum: 288
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "291FI-1120",
        value: "23.870",
        unit: "kg/s",
        tagdesc: "FLOW HP SREAM INLET TG#1",
        colnum: 290
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "291FI-1121",
        value: ".360",
        unit: "kg/s",
        tagdesc: "FLOW BLEEDING OUTLET TG#1",
        colnum: 291
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "291FI-1122",
        value: "7.610",
        unit: "kg/s",
        tagdesc: "FLOW EXTRACTION OUTLET TG#1",
        colnum: 292
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "291FI-2120",
        value: "27.440",
        unit: "kg/s",
        tagdesc: "FLOW HP SREAM INLET TG#2",
        colnum: 293
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "291FI-2121",
        value: ".200",
        unit: "kg/s",
        tagdesc: "FLOW BLEEDING OUTLET TG#2",
        colnum: 294
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "291FI-2122",
        value: "20.570",
        unit: "kg/s",
        tagdesc: "FLOW EXTRACTION OUTLET TG#2",
        colnum: 295
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "431QI_627",
        value: "655.350",
        unit: null,
        tagdesc: "BRIGHTNESS HD 1",
        colnum: 299
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "451FC-930",
        value: "655.350",
        unit: "m3/h",
        tagdesc: "LIQ FLOW FROM EFCT#2 TO EFCT#1",
        colnum: 300
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "451FC-317",
        value: "655.350",
        unit: "m3/h",
        tagdesc: "DILUT, LIQ FLOW TO DIGESTERS",
        colnum: 301
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "451ATI-802",
        value: "16.130",
        unit: "%",
        tagdesc: "WL SOLIDS",
        colnum: 302
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "451DI-315A",
        value: ".530",
        unit: "%",
        tagdesc: "%DS SBL TO EFCT#1A/1B",
        colnum: 303
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "461DI-404A",
        value: "655.350",
        unit: "%ds",
        tagdesc: "HBL SOLIDS",
        colnum: 304
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "451LI-940",
        value: "655.350",
        unit: "%",
        tagdesc: "HBL 200M3 TANK LVL",
        colnum: 305
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "451LI-311",
        value: "21.210",
        unit: "%",
        tagdesc: "LVL SBL STRG TK#1",
        colnum: 306
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "451LI-312",
        value: "655.350",
        unit: "%",
        tagdesc: "LVL SBL STRG TK#2",
        colnum: 307
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "451PC-651",
        value: "-.800",
        unit: "barE",
        tagdesc: "EFCT#5 CONDNSBL GASES",
        colnum: 308
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "451TDI-926",
        value: "655.350",
        unit: "deg C",
        tagdesc: "TEMP DIFF EFCT#3",
        colnum: 309
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "461FIS-455B",
        value: "1231.700",
        unit: "TDS/d",
        tagdesc: "BL VIRGIN",
        colnum: 310
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "461FI-137",
        value: "43.130",
        unit: "kg/s",
        tagdesc: "MAIN STEAM HEADER",
        colnum: 311
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "461FC-346",
        value: "655.350",
        unit: "m3/h",
        tagdesc: "WEAK WASH /GL FLOW",
        colnum: 312
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "461PDI-214",
        value: "6553.500",
        unit: "Pa",
        tagdesc: "TOTAL BOILER DIFF. PRESSURE",
        colnum: 313
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "461SC-248",
        value: "6553.500",
        unit: "%",
        tagdesc: "ID-FAN #1 SPEED CNTRL",
        colnum: 314
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "461SC-249",
        value: "655.350",
        unit: "%",
        tagdesc: "ID-FAN #2 SPEED CNTRL",
        colnum: 315
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "461SC-801",
        value: "655.350",
        unit: "%",
        tagdesc: "ID-FAN #3 SPEED CNTRL",
        colnum: 316
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "461AI-257",
        value: "1202.500",
        unit: "mg/m3n",
        tagdesc: "STACK DUST METER",
        colnum: 317
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "461AI-704",
        value: "655.350",
        unit: "ppm",
        tagdesc: "CEMS R/B SO2",
        colnum: 318
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "461AI-702",
        value: "10.700",
        unit: "ppm",
        tagdesc: "CEMS R/B CO",
        colnum: 319
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "462FI-244",
        value: "32.230",
        unit: "kg/s",
        tagdesc: "MAIN STEAM FLOW",
        colnum: 320
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "462TI-243",
        value: "655.350",
        unit: "deg C",
        tagdesc: "MAIN STM TEMP",
        colnum: 321
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "462PI-242",
        value: "655.350",
        unit: "barE",
        tagdesc: "MAIN STM PRESS",
        colnum: 322
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "462LI-409",
        value: "73.800",
        unit: "%",
        tagdesc: "FLY ASH SILO LEVEL",
        colnum: 323
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "462II-F003-1",
        value: "655.350",
        unit: "%",
        tagdesc: "ID FAN",
        colnum: 324
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "462TI-316",
        value: "655.350",
        unit: "deg C",
        tagdesc: "POST ECONO LEFT FLUE GAS TEMP",
        colnum: 325
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "462PDI-314",
        value: "13.780",
        unit: "kPa",
        tagdesc: "ECONOMIZER DIFF PRESS",
        colnum: 326
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "462AI-704",
        value: "6.670",
        unit: "ppm",
        tagdesc: "CEMS P/B SO2",
        colnum: 327
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "462AI-702",
        value: "141.000",
        unit: "ppm",
        tagdesc: "CEMS P/B CO",
        colnum: 328
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "292NAC1.2W",
        value: "14.840",
        unit: "MW",
        tagdesc: "22KV NAC ER201 TG1 FDR WATT",
        colnum: 329
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "292NAC1.3W",
        value: "11.500",
        unit: "MW",
        tagdesc: "22KV NAC ER201 TG#2 FDR WATT",
        colnum: 333
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "281FIQ-101",
        value: "136.800",
        unit: "m3",
        tagdesc: "FLOW OF RAW H2O TO 281 T101",
        colnum: 337
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "281FIQ-102",
        value: ".000",
        unit: "m3",
        tagdesc: "FLOW OF RAW H2O TO 281 T102",
        colnum: 338
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "281LI-141",
        value: "655.350",
        unit: "%",
        tagdesc: "LEVEL OF TANK 281 T108",
        colnum: 339
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "281QI-421",
        value: "-11.360",
        unit: "deg C",
        tagdesc: "DEWPOINT",
        colnum: 340
    },
    {
        rindex: 12467,
        ldate: "2022-05-26T13:09:00.000Z",
        dcstag: "281PC-425",
        value: "5.251",
        unit: "barE",
        tagdesc: "INSTRUMENT AIR PRESS",
        colnum: 341
    }
];
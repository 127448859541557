import React from "react";
import { Container, Row, Col, Badge, Table } from "react-bootstrap";
import Datacard from "../datacard/datacard";

import "./brownstockdashboard.css";

const Brownstockdashboard = (props) => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <Container fluid>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  PRI. KNOT
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "421QC-004")} />
                <Datacard title={dcsTagFinder(props.data, "421II-E004")} />
              </Col>
            </Row>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  SEC. KNOT
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "421II-E005")} />
                <Datacard title={dcsTagFinder(props.data, "421PC020")} />
              </Col>
            </Row>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  KNOT SEPA LEVEL
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "421LC-017")} />
              </Col>
            </Row>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  WASH PRESS 1
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "421FC-107")} />
                <Datacard title={dcsTagFinder(props.data, "421FC-108")} />
                <Datacard title={dcsTagFinder(props.data, "421II-E001-1")} />
                <Datacard title={dcsTagFinder(props.data, "421PC-120")} />
                <Datacard title={dcsTagFinder(props.data, "421II-C001-1")} />
                <Datacard title={dcsTagFinder(props.data, "421LC-110")} />
                <Datacard title={dcsTagFinder(props.data, "421PDI-103")} />
                <Datacard title={dcsTagFinder(props.data, "421QI-004.4")} />
              </Col>
            </Row>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  LC TANK
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "421LI201")} />
                <Datacard title={dcsTagFinder(props.data, "421QC-205")} />
              </Col>
            </Row>
          </Container>
        </Col>
        <Col>
          <Container fluid>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  PRI. SCREEN
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "421II-E009")} />
                <Datacard title={dcsTagFinder(props.data, "421PDI-210")} />
              </Col>
            </Row>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  SEC. SCREEN
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "421II-E010")} />
                <Datacard title={dcsTagFinder(props.data, "421PDI-216")} />
              </Col>
            </Row>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  TER. SCREEN
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "421II-E011")} />
                <Datacard title={dcsTagFinder(props.data, "421PDI-220")} />
              </Col>
            </Row>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  REJECT SCREW PRESS
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "421LC-230")} />
                <Datacard title={dcsTagFinder(props.data, "421LC-301")} />
                <Datacard title={dcsTagFinder(props.data, "421WI-E012")} />
                <Datacard title={dcsTagFinder(props.data, "421II-P015.1")} />
                <Datacard title={dcsTagFinder(props.data, "421II-P015.2")} />
                <Datacard title={dcsTagFinder(props.data, "421QI-205.2")} />
              </Col>
            </Row>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  PRE O2 WASH PRESS
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "421FC-312")} />
                <Datacard title={dcsTagFinder(props.data, "421FC-313")} />
                <Datacard title={dcsTagFinder(props.data, "421II-E002-1")} />
                <Datacard title={dcsTagFinder(props.data, "421PC-324")} />
                <Datacard title={dcsTagFinder(props.data, "421II-C002-1")} />
                <Datacard title={dcsTagFinder(props.data, "421FC-338")} />
                <Datacard title={dcsTagFinder(props.data, "421LI-401")} />
                <Datacard title={dcsTagFinder(props.data, "421LC-316")} />
              </Col>
            </Row>
          </Container>
        </Col>
        <Col>
          <Container fluid>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  O2 RXT1
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "421FC-409")} />
                <Datacard title={dcsTagFinder(props.data, "421QC-412")} />
                <Datacard title={dcsTagFinder(props.data, "421FC-405")} />
                <Datacard title={dcsTagFinder(props.data, "421FI-418-7")} />
                <Datacard title={dcsTagFinder(props.data, "421TC-410")} />
                <Datacard title={dcsTagFinder(props.data, "421QI-412-4")} />
                <Datacard title={dcsTagFinder(props.data, "421PI-451")} />
              </Col>
            </Row>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  O2 RXT2
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "421PC-430")} />
                <Datacard title={dcsTagFinder(props.data, "421XI-466")} />
                <Datacard title={dcsTagFinder(props.data, "421TC-473")} />
                <Datacard title={dcsTagFinder(props.data, "421FI-457-1")} />
                <Datacard title={dcsTagFinder(props.data, "421PC-478")} />
              </Col>
            </Row>
            <Row className="bg-secondary bg-gradient mb-1">
              <Col>
                <div className="bstname fw-bold text-center text-white">
                  POST O2 WASH PRESS
                </div>
              </Col>
              <Col sm={9}>
                <Datacard title={dcsTagFinder(props.data, "421LI-501")} />
                <Datacard title={dcsTagFinder(props.data, "421QC-507")} />
                <Datacard title={dcsTagFinder(props.data, "421FC-511")} />
                <Datacard title={dcsTagFinder(props.data, "421FC-512")} />
                <Datacard title={dcsTagFinder(props.data, "421II-E003-1")} />
                <Datacard title={dcsTagFinder(props.data, "421PC-527")} />
                <Datacard title={dcsTagFinder(props.data, "421II-C003")} />
                <Datacard title={dcsTagFinder(props.data, "421FC-541")} />
                <Datacard title={dcsTagFinder(props.data, "421LC-519")} />
                <Datacard title={dcsTagFinder(props.data, "421LC-603")} />
                <Datacard title={dcsTagFinder(props.data, "421QI-002")} />
                <Datacard title={dcsTagFinder(props.data, "421QI-341")} />
                <Datacard title={dcsTagFinder(props.data, "421QI-542")} />
                <Datacard title={dcsTagFinder(props.data, "421QI-507.4")} />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Brownstockdashboard;

function dcsTagFinder(data, tagname) {
  const filterArray = data.filter((item) => item.dcstag === tagname);
  return filterArray[0] || { dcstag: "x", tagdesc: "x", value: "x" };
}

import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";

function handleLogout(instance) {
  instance.logoutRedirect().catch((e) => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = () => {
  const { instance } = useMsal();

  return (
    <Button
      variant="light"
      className="ml-auto"
      onClick={() => handleLogout(instance)}
    >
      <img src="mslogo.png" alt="" style={{ marginRight: "12px" }} />
      <span className="msbutton">Sign Out</span>
    </Button>
  );
};

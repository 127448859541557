import React from "react";
import { Container, Row, Col, Badge, Table } from "react-bootstrap";
import Datacard from "../datacard/datacard";

import "./cookingdashboard.css";

const Cookingdashboard = (props) => {
  return (
    <Container fluid>
      <Row>
        <Col md lg="10">
          <Container fluid>
            <Row className="cookingrow">
              <Col sm></Col>
              <Col sm>
                Digester 1<br />
                {dcsTagValue(props.data, "411DIG01MODE")}
              </Col>
              <Col sm>
                Digester 4<br />
                {dcsTagValue(props.data, "411DIG04MODE")}
              </Col>
              <Col sm>
                Digester 2<br />
                {dcsTagValue(props.data, "411DIG02MODE")}
              </Col>
              <Col sm>
                Digester 5<br />
                {dcsTagValue(props.data, "411DIG05MODE")}
              </Col>
              <Col sm>
                Digester 3<br />
                {dcsTagValue(props.data, "411DIG03MODE")}
              </Col>
            </Row>
            <Row className="cookingrow">
              <Col md>Discharge</Col>
              <Col md>
                <Datacard title={dcsTagFinder(props.data, "411WI107")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ612-D01")} />
              </Col>
              <Col md>
                <Datacard title={dcsTagFinder(props.data, "411WI407")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ612-D04")} />
              </Col>
              <Col md>
                <Datacard title={dcsTagFinder(props.data, "411WI207")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ612-D02")} />
              </Col>
              <Col md>
                <Datacard title={dcsTagFinder(props.data, "411WI507")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ612-D05")} />
              </Col>
              <Col md>
                <Datacard title={dcsTagFinder(props.data, "411WI307")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ612-D03")} />
              </Col>
            </Row>
            <Row className="cookingrow">
              <Col md>Displacement</Col>
              <Col md>
                <Datacard title={dcsTagFinder(props.data, "411FQ614WSP01")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ614-D01")} />
              </Col>
              <Col md>
                <Datacard title={dcsTagFinder(props.data, "411FQ614WSP04")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ614-D04")} />
              </Col>
              <Col md>
                <Datacard title={dcsTagFinder(props.data, "411FQ614WSP02")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ614-D02")} />
              </Col>
              <Col md>
                <Datacard title={dcsTagFinder(props.data, "411FQ614WSP05")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ614-D05")} />
              </Col>
              <Col md>
                <Datacard title={dcsTagFinder(props.data, "411FQ614WSP03")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ614-D03")} />
              </Col>
            </Row>
            <Row className="cookingrow">
              <Col>Heat & Cook</Col>
              <Col>
                <Datacard title={dcsTagFinder(props.data, "411HFACTWSP01")} />
                <Datacard title={dcsTagFinder(props.data, "411HFACT01")} />
                <Datacard title={dcsTagFinder(props.data, "411COTMPWSP01")} />
                <Datacard title={dcsTagFinder(props.data, "411HFACT01TEMP")} />
              </Col>
              <Col>
                <Datacard title={dcsTagFinder(props.data, "411HFACTWSP04")} />
                <Datacard title={dcsTagFinder(props.data, "411HFACT04")} />
                <Datacard title={dcsTagFinder(props.data, "411COTMPWSP04")} />
                <Datacard title={dcsTagFinder(props.data, "411HFACT04TEMP")} />
              </Col>
              <Col>
                <Datacard title={dcsTagFinder(props.data, "411HFACTWSP02")} />
                <Datacard title={dcsTagFinder(props.data, "411HFACT02")} />
                <Datacard title={dcsTagFinder(props.data, "411COTMPWSP02")} />
                <Datacard title={dcsTagFinder(props.data, "411HFACT02TEMP")} />
              </Col>
              <Col>
                <Datacard title={dcsTagFinder(props.data, "411HFACTWSP05")} />
                <Datacard title={dcsTagFinder(props.data, "411HFACT05")} />
                <Datacard title={dcsTagFinder(props.data, "411COTMPWSP05")} />
                <Datacard title={dcsTagFinder(props.data, "411HFACT05TEMP")} />
              </Col>
              <Col>
                <Datacard title={dcsTagFinder(props.data, "411HFACTWSP03")} />
                <Datacard title={dcsTagFinder(props.data, "411HFACT03")} />
                <Datacard title={dcsTagFinder(props.data, "411COTMPWSP03")} />
                <Datacard title={dcsTagFinder(props.data, "411HFACT03TEMP")} />
              </Col>
            </Row>
            <Row className="cookingrow">
              <Col md={2} lg={2}>
                Hot Liquor Filling
              </Col>
              <Col xl>
                <Datacard title={dcsTagFinder(props.data, "411FQ818WSP01")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ818-D01")} />
              </Col>
              <Col xl>
                <Datacard title={dcsTagFinder(props.data, "411FQ804WSP01")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ804-D01")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ804NWSP01")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ804N-D01")} />
              </Col>
              <Col xl>
                <Datacard title={dcsTagFinder(props.data, "411FQ818WSP04")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ818-D04")} />
              </Col>
              <Col xl>
                <Datacard title={dcsTagFinder(props.data, "411FQ804WSP04")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ804-D04")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ804NWSP04")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ804N-D04")} />
              </Col>
              <Col xl>
                <Datacard title={dcsTagFinder(props.data, "411FQ818WSP02")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ818-D02")} />
              </Col>
              <Col xl>
                <Datacard title={dcsTagFinder(props.data, "411FQ804WSP02")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ804-D02")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ804NWSP02")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ804N-D02")} />
              </Col>
              <Col xl>
                <Datacard title={dcsTagFinder(props.data, "411FQ818WSP05")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ818-D05")} />
              </Col>
              <Col xl>
                <Datacard title={dcsTagFinder(props.data, "411FQ804WSP05")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ804-D05")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ804NWSP05")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ804N-D05")} />
              </Col>
              <Col xl>
                <Datacard title={dcsTagFinder(props.data, "411FQ818WSP03")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ818-D03")} />
              </Col>
              <Col xl>
                <Datacard title={dcsTagFinder(props.data, "411FQ804WSP03")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ804-D03")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ804NWSP03")} />
                <Datacard title={dcsTagFinder(props.data, "411FQ804N-D03")} />
              </Col>
            </Row>
            <Row className="cookingrow">
              <Col md></Col>
              <Col md>
                <Datacard title={dcsTagFinder(props.data, "411CHF107")} />
                <Datacard title={dcsTagFinder(props.data, "411TARA107")} />
              </Col>
              <Col md>
                <Datacard title={dcsTagFinder(props.data, "411CHF407")} />
                <Datacard title={dcsTagFinder(props.data, "411TARA407")} />
              </Col>
              <Col md>
                <Datacard title={dcsTagFinder(props.data, "411CHF207")} />
                <Datacard title={dcsTagFinder(props.data, "411TARA207")} />
              </Col>
              <Col md>
                <Datacard title={dcsTagFinder(props.data, "411CHF507")} />
                <Datacard title={dcsTagFinder(props.data, "411TARA507")} />
              </Col>
              <Col md>
                <Datacard title={dcsTagFinder(props.data, "411CHF307")} />
                <Datacard title={dcsTagFinder(props.data, "411TARA307")} />
              </Col>
            </Row>
            <Row className="cookingrow">
              <Col md></Col>
              <Col md>
                <Datacard title={dcsTagFinder(props.data, "411LAH001")} />
              </Col>
              <Col md>
                <Datacard title={dcsTagFinder(props.data, "411LAH004")} />
              </Col>
              <Col md>
                <Datacard title={dcsTagFinder(props.data, "411LAH002")} />
              </Col>
              <Col md>
                <Datacard title={dcsTagFinder(props.data, "411LAH005")} />
              </Col>
              <Col md>
                <Datacard title={dcsTagFinder(props.data, "411LAH003")} />
              </Col>
            </Row>
            <Row className="cookingrow">
              <Col md={2} lg={2}></Col>
              <Col sm>
                <Datacard title={dcsTagFinder(props.data, "411LI904")} />
              </Col>
              <Col sm>
                <Datacard title={dcsTagFinder(props.data, "411LC703.1")} />
              </Col>
              <Col sm>
                <Datacard title={dcsTagFinder(props.data, "411QC855")} />
              </Col>
            </Row>
            <Row className="cookingrow">
              <Col md={2} lg={2}></Col>
              <Col sm>
                <Datacard title={dcsTagFinder(props.data, "411PC805.1")} />
              </Col>
              <Col sm>
                <Datacard title={dcsTagFinder(props.data, "411PC809.1")} />
              </Col>
              <Col sm>
                <Datacard title={dcsTagFinder(props.data, "411PC820.1")} />
              </Col>
            </Row>
          </Container>
        </Col>
        <Col>
          <Container fluid>
            <Row className="cookingrow">
              <Col>
                <Datacard title={dcsTagFinder(props.data, "411PI923.1")} />
              </Col>
              <Col>
                <Datacard title={dcsTagFinder(props.data, "411COUNDIG5")} />
              </Col>
            </Row>
            <Row className="cookingrow">
              <Col>
                <Datacard title={dcsTagFinder(props.data, "411LI609")} />
              </Col>
              <Col>
                <Datacard title={dcsTagFinder(props.data, "411LI609")} />
              </Col>
            </Row>
            <Row className="cookingrow">
              <Col>
                <Datacard title={dcsTagFinder(props.data, "411LI618")} />
              </Col>
              <Col>
                <Datacard title={dcsTagFinder(props.data, "451ATI802")} />
              </Col>
            </Row>
            <Row className="cookingrow">
              <Col>
                <Datacard title={dcsTagFinder(props.data, "411LI817")} />
              </Col>
              <Col>
                <Datacard title={dcsTagFinder(props.data, "321II-C021-1")} />
              </Col>
            </Row>
            <Row className="cookingrow">
              <Col>
                <Datacard title={dcsTagFinder(props.data, "411LI807")} />
              </Col>
              <Col>
                <Datacard title={dcsTagFinder(props.data, "321II-C021-1")} />
              </Col>
            </Row>
            <Row className="cookingrow">
              <Col>
                <Datacard title={dcsTagFinder(props.data, "411LI803")} />
              </Col>
              <Col>
                <Datacard title={dcsTagFinder(props.data, "411PI602")} />
              </Col>
            </Row>
            <Row className="cookingrow">
              <Col>
                <Datacard title={dcsTagFinder(props.data, "411LC625")} />
              </Col>
              <Col>
                <Datacard title={dcsTagFinder(props.data, "411TI601")} />
              </Col>
            </Row>
            <Row className="cookingrow">
              <Col>
                <Datacard title={dcsTagFinder(props.data, "471LI190")} />
              </Col>
              <Col>
                <Datacard title={dcsTagFinder(props.data, "431PI-033")} />
              </Col>
            </Row>
            <Row className="cookingrow">
              <Col>
                <Datacard title={dcsTagFinder(props.data, "411LI659")} />
              </Col>
              <Col>
                <Datacard title={dcsTagFinder(props.data, "451LI805")} />
              </Col>
            </Row>
            <Row className="cookingrow">
              <Col>
                <Datacard title={dcsTagFinder(props.data, "421LI001")} />
              </Col>
              <Col>
                <Datacard title={dcsTagFinder(props.data, "411LC913")} />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

function dcsTagFinder(data, tagname) {
  const filterArray = data.filter((item) => item.dcstag === tagname);
  return filterArray[0] || { dcstag: "x", tagdesc: "x", value: "x" };
}

function dcsTagValue(data, tagname) {
  const filterArray = data.filter((item) => item.dcstag === tagname);
  if (filterArray[0].value === 1) return <Badge bg="primary">Manual</Badge>;
  if (filterArray[0].value === 2) return <Badge bg="success">Auto</Badge>;
  if (filterArray[0].value === 3) return <Badge bg="warning">Step</Badge>;
  return <Badge bg="danger">x</Badge>;
}

export default Cookingdashboard;
